<template>
  <div id="AbcProductos">
    <BaseBackBar titulo="Productos" goTo="/app/catalogos" />
    <ListaProductosAbc
      ref="ListaProductosAbc"
      @row-selected="onRowSelected"
      :listaProveedoresSelect="listaProveedoresSelect"
      :listaMarcasSelect="listaMarcasSelect"
    />
    <Detalles
      ref="sidebar-detalles-producto"
      @productoModificado="reloadProductos"
      :currentProducto="currentProducto"
      :listaProveedoresSelect="listaProveedoresSelect"
      :listaMarcasSelect="listaMarcasSelect"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseBackBar from "../../../../components/base/_BaseBackBar.vue";
import ListaProductosAbc from "./forms/ListaProductosAbc.vue";
import Detalles from "./forms/Detalles.vue";

export default {
  name: "AbcProductos",
  props: [],
  components: {
    BaseBackBar,
    ListaProductosAbc,
    Detalles,
  },
  data() {
    return {
      currentProducto: null,
    };
  },
  computed: {
    ...mapGetters([
      "listaProveedoresSelect",
      "listaMarcasSelect",
      "menuAbierto",
    ]),
  },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
      obtenerMarcas: "ObtenerMarcas",
      selectProducto: "SelectProducto",
      AbrirEstadoMenuDetalles: "AbrirEstadoMenuDetalles",
      CerrarEstadoMenuDetalles: "CerrarEstadoMenuDetalles",
    }),
    onRowSelected(item) {
      if (item.length < 1) {
        this.CerrarEstadoMenuDetalles();
      } else {
        this.currentProducto = item[0];
        this.AbrirEstadoMenuDetalles();
      }
    },
    reloadProductos() {
      //this.$refs.ListaProductosAbc.buscarProductos();
    },
  },
  mounted() {
    this.obtenerProveedores();
    this.obtenerMarcas();
  },
};
</script>
<style lang="scss">
@import "../../../../styles/views/AbcProductos.scss";
</style>