<template>
  <div>
    <div class="input-bar">
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Seleccione o arrastre una imagen..."
        drop-placeholder="Arrastre la imagen..."
        accept=".jpg, .png, .gif"
        :multiple="aceptarMultiple"
      ></b-form-file>
      <b-button style="background-color: #355070" @click="guardarImagen"
        >Guardar</b-button
      >
    </div>
    <div class="mt-3">Imagen seleccionada: {{ file ? file.name : "" }}</div>

    <b-container fluid class="p-4 bg-dark">
      <b-row>
        <b-col
          v-for="(image, index) in normalizedRutas"
          :key="index"
          class="mb-3"
        >
          <a :href="image.image" target="_blank"><b-img thumbnail fluid :src="image.image" /></a>
          <div>
            <div class="mt-3 text-center">
              <b-button-group>
                <b-button variant="danger" size="sm" @click="borrarImagen(image)">Eliminar</b-button>
              </b-button-group>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!--<ul class="image-wrapper">
      <li
        v-for="(image, index) in normalizedRutas"
        :key="index"
        @click="borrarImagen(image)"
      >
        <img class="image-item" :src="image.image" alt="image" />
      </li>
    </ul>-->
  </div>
</template>
<script>
import api from "../../src/utils/api";
import Notification from "../utils/notification";
export default {
  name: "ImageUploader",
  props: ["rutasImagen", "productoId", 'codigo', "aceptarMultiple", "imagen_id", "documento_id", "tipo_documento"],
  data() {
    return {
      file: null,
    };
  },
  computed: {
    normalizedRutas: function () {
      if(this.tipo_documento) { // 'FACTURA', 'DESCUENTO', 'PAGO'
        return this.rutasImagen.map(({ Path, ImagenID }) => {
          return {
            image: `${process.env.VUE_APP_JPR_API_URL}imagenes/obtener/${Path}`,
            path: Path,
            imagenId: ImagenID
          };
        });
      } else {
        return this.rutasImagen.map(({ path, imagen_producto_id }) => {
          return {
            image: `${process.env.VUE_APP_JPR_API_URL}productos-imagen/obtener/${path}`,
            path: path,
            imagenId: imagen_producto_id,
          };
        });
      }
    },
  },
  methods: {
    //TODO: handle single image again
    guardarImagen() {
      this.file.forEach((imagen) => {
        const formData = new FormData();
        formData.append("image", imagen);
        const url = (this.tipo_documento)
          ? `imagenes/agregar/${this.documento_id}/${this.tipo_documento}/${sessionStorage.getItem('usuario_id')}`
          : `productos-imagen/agregar/${this.productoId}/${this.codigo}/${sessionStorage.getItem('usuario_id')}`; 

        api("POST", url, formData, {
          headers: { "Content-type": "multipart/form-data" },
        })
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error al guardar la imagen",
                text: "No has subido ningun archivo o usaste un archivo incorrecto",
                type: "error",
              });
            } else if (result.affectedRows === 1) {
              this.$emit("reload-imagenes");
              this.$notify({
                group: "foo",
                title: "Imagen guardada",
                text: "",
                type: "success",
              });
            }
          })
          .catch((err) => {
            this.$notify({
              group: "foo",
              title: "Error al guardar la imagen",
              text: "Error del servidor",
              type: "error",
            });
          });
      });
    },
    borrarImagen(imagen) {
      console.log("IMAGEN", imagen);
      if (confirm("Desea eliminar la foto?")) {
        const url = (this.tipo_documento) 
        ? `imagenes/eliminar/${imagen.imagenId}/${imagen.path}`
        : `productos-imagen/eliminar/${imagen.imagenId}/${imagen.path}`
        api(
          "DELETE",
          url
        )
          .then(() => {
            Notification("success", "Borrar Imagen", "Imagen Eliminada");
          })
          .catch(() => {
            Notification("error", "Borrar Imagen", "Imagen NO Eliminada");
          });
        this.$emit("reload-imagenes");
      }
    },
  },
};
</script>

<style>
.custom-file {
  margin-right: 10px;
}
.input-bar {
  display: flex;
  flex-direction: row;
}
.image-wrapper {
  margin: 30px auto;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px;
}
.image-item {
  max-height: 90px;
  max-width: 90px;
}
.image-wrapper > li {
  border: 1px black solid;
  margin: 1px;
  position: relative;
  height: 100px;
  width: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
}
li > img {
  justify-content: center;
  display: flex;
  align-items: center;
}
.image-wrapper > li:hover {
  cursor: pointer;
}
.image-wrapper > li::after {
  content: "x";
  font-weight: 700;
  font-size: 19px;
  position: absolute;
  top: -7px;
  right: 3px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar" !important;
}
</style>
