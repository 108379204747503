<template>
  <div class="detallesWrapper">
    <b-sidebar id="sidebar-detalles-producto" ref="sidebar-detalles-producto" no-header right bg-variant="white" shadow
      width="35%" :visible="menuDetallesAbierto" backdrop>
      <b-overlay :show="loading">
        <div class="detalles_header">
          <b-icon id="x-icon" @click="clickMenu" icon="x"></b-icon>
        </div>

        <div class="detalles_body">
          <b-row>
            <b-col>
              <h4 class="fill"></h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="header_item">
                <p class="txt_dato" style="font-size: 30px">Detalles</p>
              </div>
              <Carousel class="carrousell" v-bind:rutasImagen="rutasImagen" />
              <div class="dato_item">
                <p class="txt_dato">ID:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.producto_id || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Código:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.codigo || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Subcódigo:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.subcodigo || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Código Fábrica:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.codigo_fab || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Descripción:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.descripcion || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Marca:</p>
                <p class="txt_dato_resp">{{ this.marca.nombre || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Proveedor:</p>
                <p class="txt_dato_resp">{{ this.proveedor.nombre || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Empaque:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.empaque || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Creación:</p>
                <p class="txt_dato_resp">
                  {{ this.precio_inicial.creacion || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Usuario que agregó el producto:</p>
                <p class="txt_dato_resp">
                  {{ this.precio_inicial.nombre || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Usuario que modificó el producto:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.usuario_modificacion_nombre || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Venta Mínima:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.venta_min || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">IVA:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.iva || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">I.E.P.S:</p>
                <p class="txt_dato_resp">
                  {{ this.productoSelected.ieps || " - " }}
                </p>
              </div>
              <div class="header_item">
                <p class="txt_dato" style="font-size: 30px">Precios</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato" style="text-align: start !important">
                  Usuario que modificó los precios la última vez:
                </p>
                <p class="tex_dato_resp">
                  {{ this.precio_anterior.nombre || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato" style="text-align: start !important">
                  Última Modificación de Precios:
                </p>
                <p class="tex_dato_resp">
                  {{ this.precio.ultima_modificacion || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio Lista</p>
                <p class="tex_dato_resp">
                  ${{ this.precio.precio_lista || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 1:</p>
                <p class="txt_dato_resp">${{ this.precio.precio_1 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 2:</p>
                <p class="txt_dato_resp">${{ this.precio.precio_2 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 3:</p>
                <p class="txt_dato_resp">${{ this.precio.precio_3 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 4:</p>
                <p class="txt_dato_resp">${{ this.precio.precio_4 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 5:</p>
                <p class="txt_dato_resp">${{ this.precio.precio_5 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Comisión 1:</p>
                <p class="txt_dato_resp">{{ this.precio.factor_1 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Comisión 2:</p>
                <p class="txt_dato_resp">{{ this.precio.factor_2 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Comisión 3:</p>
                <p class="txt_dato_resp">{{ this.precio.factor_3 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Comisión 4:</p>
                <p class="txt_dato_resp">{{ this.precio.factor_4 || " - " }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Comisión 5:</p>
                <p class="txt_dato_resp">{{ this.precio.factor_5 || " - " }}</p>
              </div>
              <div class="header_item">
                <p class="txt_dato" style="font-size: 30px">Precios Iniciales</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 1:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_inicial.precio_1 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 2:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_inicial.precio_2 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 3:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_inicial.precio_3 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 4:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_inicial.precio_4 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 5:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_inicial.precio_5 || " - " }}
                </p>
              </div>
              <div class="header_item">
                <p class="txt_dato" style="font-size: 30px">Precios Anteriores</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 1:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_anterior.precio_1 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 2:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_anterior.precio_2 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 3:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_anterior.precio_3 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 4:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_anterior.precio_4 || " - " }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Precio 5:</p>
                <p class="txt_dato_resp">
                  ${{ this.precio_anterior.precio_5 || " - " }}
                </p>
              </div>
              <div>
                <b-button block style="background-color: #355070"
                  @click="abrirModalModificarProducto">Modificar</b-button>
                <b-button @click="abrirModalImageUploader" block style="background-color: #355070">
                  <b-icon icon="camera" variant="light" />
                </b-button>
                <b-button block @click="suspenderProducto" style="background-color: #a5594e; border-color: #a5594e">
                  Suspender</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-modal id="modal-agregar-imagenes" ref="modal-agregar-imagenes" size="lg" title="Agregar Imagenes" scrollable
      hide-footer>
      <ImageUploader v-bind:rutasImagen="rutasImagen" v-bind:productoId="this.productoSelected.producto_id"
        v-bind:codigo="this.productoSelected.codigo" @reload-imagenes="recargarImagenes" :aceptarMultiple="true" />
    </b-modal>
    <b-modal id="modal-modificar-producto" ref="modal-modificar-producto" size="lg" title="Modificar producto"
      scrollable hide-footer>
      <ModificarProducto :selected="productoSelected" :listaProveedoresSelect="listaProveedoresSelect"
        :listaMarcasSelect="listaMarcasSelect" :precios="precio" v-on:productoModificado="reloadDetalles" />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Notification from "../../../../../utils/notification";
import api from "../../../../../utils/api";
import Carousel from "../../../../../components/base/_BaseCaroussell.vue";
import ImageUploader from "../../../../../components/ImageUploader.vue";
import ModificarProducto from "./ModificarProducto.vue";

export default {
  name: "Detalles",
  props: ["currentProducto", "listaMarcasSelect", "listaProveedoresSelect"],
  components: {
    Carousel,
    ImageUploader,
    ModificarProducto,
  },
  data() {
    return {
      productoSelected: {},
      rutasImagen: [],
      precio_anterior: {},
      precio_inicial: {},
      marca: {},
      proveedor: {},
      precio: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["menuDetallesAbierto"]),
  },
  watch: {
    menuDetallesAbierto: function () {
      if (this.menuDetallesAbierto) {
        this.getDetallesProducto(this.currentProducto);
      }
    },
  },
  mounted() {
    if (this.currentProducto) {
      this.getDetallesProducto(this.currentProducto);
    }
  },
  methods: {
    //TODO - crear un solo metodo para consultar detalles
    ...mapActions({
      CambiarEstadoMenuDetalles: "CambiarEstadoMenuDetalles",
    }),
    recargarImagenes() {
      this.getDetallesProducto(this.currentProducto);
    },
    clickMenu() {
      this.CambiarEstadoMenuDetalles();
    },
    abrirModalImageUploader() {
      this.$refs["modal-agregar-imagenes"].show();
    },
    abrirModalModificarProducto() {
      this.$refs["modal-modificar-producto"].show();
    },
    reloadDetalles() {
      if (this.currentProducto) {
        this.getDetallesProducto(this.currentProducto);
        this.$emit("productoModificado");
        this.$refs["modal-modificar-producto"].hide();
      }
    },
    async getDetallesProducto(producto) {
      if (producto) {
        this.loading = true;
        let id = producto.producto_id;
        try {
          const result = await api("GET", `productos/obtener-producto/${id}`);

          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al roles del personal",
              text: "Error del servidor",
              type: "error",
            });
            return;
          }

          this.productoSelected = result;
          await Promise.all([
            this.getProveedor(this.productoSelected.proveedor_id), 
            this.getMarca(this.productoSelected.marca_id), 
            this.getPrecios(this.productoSelected.precio_id), 
            this.getPreciosIniciales(this.productoSelected.precio_inicial_id), 
            this.getRutasImagenes(this.productoSelected.producto_id)
          ])

          if (this.productoSelected.precio_anterior_id) {
            await this.getPreciosAnteriores(this.productoSelected.precio_anterior_id);
          }

        } catch (error) {
          Notification('error', 'Detalle', error.response.data.msg || 'Hubo un error al obtener los detalles del producto.', 3000);
        } finally {
          this.loading = false;
        }
      }
    },
    async getProveedor(id) {
      try {
        const result = await api("GET", `productos/obtener-proveedor/${id}`);

        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al roles del personal",
            text: "Error del servidor",
            type: "error",
          });
          return;
        }

        this.proveedor = result;
      } catch (error) {
        Notification('error', 'Proveedor', error.response.data.msg || 'Hubo un error al consultar el proveedor.', 3000);
      }
    },
    async getRutasImagenes(id) {
      try {
        const result = await api("GET", `productos-imagen/obtener-imagenes/${id}`);

        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Imagenes no encontradas",
            text: "Error del servidor",
            type: "error",
          });
          this.rutasImagen = [];
          return;
        }

        this.rutasImagen = [...result];
      } catch (error) {
        Notification('error', 'Imágenes', error.response.data.msg || 'Hubo un error al consultar las imágenes.', 3000);
      }
    },
    async getMarca(id) {
      try {
        const result = await api("GET", `productos/obtener-marca/${id}`);

        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al roles del personal",
            text: "Error del servidor",
            type: "error",
          });
          return;
        }

        this.marca = result;
      } catch (error) {
        Notification('error', 'Marca', error.response.data.msg || 'Hubo un error al consultar la marca', 3000);
      }
    },
    async getPrecios(id) {
      try {
        const result = await api("GET", `productos/obtener-precio/${id}`);

        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al roles del personal",
            text: "Error del servidor",
            type: "error",
          });
          return;
        }

        this.precio = result;
      } catch (error) {
        Notification('error', 'Precios', error.response.data.msg || 'Hubo un error al consultar los precios.', 3000);
      }
    },
    async getPreciosIniciales(id) {
      try {
        const result = await api("GET", `productos/obtener-precio-inicial/${id}`);

        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al roles del personal",
            text: "Error del servidor",
            type: "error",
          });
          return;
        }

        this.precio_inicial = result;
      } catch (error) {
        Notification('error', 'Precios iniciales', error.response.data.msg || 'Hubo un error al consultar los precios iniciales.', 3000);
      }
    },
    async getPreciosAnteriores(id) {
      try {
        const result = await api("GET", `productos/obtener-precio-anterior/${id}`)

        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al roles del personal",
            text: "Error del servidor",
            type: "error",
          });
          return;
        }
        this.precio_anterior = result;
      } catch (error) {
        Notification('error', 'Precios anteriores', error.response.data.msg || 'Hubo un error al consultar los precios anteriores.', 3000);
      }
    },
    suspenderProducto() {
      api(
        "get",
        `producto/suspender-producto/${this.currentProducto.producto_id
        }/${sessionStorage.getItem("usuario_id")}/${this.currentProducto.codigo
        }`
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al suspender el producto",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.CambiarEstadoMenuDetalles();
            this.$emit("reloadProductList", true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.dato_item {
  text-align: end !important;
}
</style>