<template>
  <div class="modioficar_producto_container">
    <b-overlay :show="isBusy">
      <b-row>
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="codigo">Codigo: </label>
            <b-form-input v-model="selected.codigo" id="codigo" type="text"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="subcodigo">Sub-Codigo: </label>
            <b-form-input id="subcodigo" v-model="selected.subcodigo" type="text"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="codigo_fabrica">Codigo Fabrica:
            </label>
            <b-form-input id="codigo_fabrica" v-model="selected.codigo_fab" type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: 10px">
        <b-col>
          <label style="margin-right: 10px" for="descripcion">Descripción:
          </label>
          <b-form-input id="descripcion" v-model="selected.descripcion" type="text"></b-form-input>
        </b-col>
      </b-row>
      <b-row style="margin-top: 10px">
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="codigo">Marca: </label>
            <b-form-select v-model="selected.marca_id" :options="listaMarcasSelect"></b-form-select>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="codigo">Proveedor: </label>
            <b-form-select v-model="proveedor_id" :options="listaProveedoresSelect"></b-form-select>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: 10px">
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="empaque">Empaque: </label>
            <b-form-input id="empaque" v-model="selected.empaque" type="text"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input_row">
            <label style="margin-right: 10px" for="iva">IVA: </label>
            <b-form-input id="iva" v-model="selected.iva" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input_row">
            <label style="margin-right: 10px" for="ieps">I.E.P.S.: </label>
            <b-form-input id="ieps" v-model="selected.ieps" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label style="margin-right: 10px" for="venta_min">Venta Minima:
            </label>
            <b-form-input id="venta_min" v-model="selected.venta_min" type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5>Modificar Precios</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <label for="factor_1">Comision 1:</label>
            <b-form-input id="factor_1" v-model="precios.factor_1" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_2">Comision 2:</label>
            <b-form-input id="factor_2" v-model="precios.factor_2" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_3">Comision 3:</label>
            <b-form-input id="factor_3" v-model="precios.factor_3" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_4">Comision 4:</label>
            <b-form-input id="factor_4" v-model="precios.factor_4" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_5">Comision 5:</label>
            <b-form-input id="factor_5" v-model="precios.factor_5" type="number"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="precio_1">Precio Lista:</label>
          <b-form-input id="precio_1" v-model="precio_lista" type="number"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <label for="precio_1">Precio 1:</label>
            <b-form-input id="precio_1" v-model="precios.precio_1" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_2">Precio 2:</label>
            <b-form-input id="precio_2" v-model="precios.precio_2" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_3">Precio 3:</label>
            <b-form-input id="precio_3" v-model="precios.precio_3" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_4">Precio 4:</label>
            <b-form-input id="precio_4" v-model="precios.precio_4" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_5">Precio 5:</label>
            <b-form-input id="precio_5" v-model="precios.precio_5" type="number"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: 20px">
        <b-col>
          <b-button style="
              margin-right: 15px;
              background-color: #355070;
              border-color: #355070;
            " block @click="modificarProducto" :disabled="isBusy">GUARDAR</b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import api from "../../../../../utils/api";
export default {
  name: "ModificarProducto",
  props: ["selected", "listaMarcasSelect", "listaProveedoresSelect", "precios"],
  data() {
    return {
      isBusy: false,
      precio_lista: 0,
      preciosData: this.precios,
      proveedor_id: null,
      comision_1: 1,
      comision_2: 1,
      comision_3: 1,
      comision_4: 1,
      comision_5: 1,
    };
  },
  watch: {
    proveedor_id: function (val) {
      this.obtenerComisiones(val);
    },
    precio_lista: function (val) {
      this.calcularPrecios(val);
    },
  },
  mounted() {
    this.proveedor_id = this.selected.proveedor_id;
    this.precio_lista = this.precios.precio_lista;
  },
  methods: {
    modificarProducto() {
      this.isBusy = true;
      this.selected.proveedor_id = this.proveedor_id;
      this.selected.usuario_id = sessionStorage.getItem("usuario_id");
      this.selected = {
        ...this.selected,
        ...this.precios,
        precio_lista: this.precio_lista,
      };
      api("PUT", `productos-paginados/modificar-producto`, this.selected)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar el producto",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$notify({
              group: "foo",
              title: "Productro modificado",
              text: "Producto modificado con exito!",
              type: "success",
            });
            this.$emit("productoModificado");
          }
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            title: "Error al modificar el producto",
            text: "Error del servidor",
            type: "success",
          });
        }).finally(() => this.isBusy = false);
    },
    calcularPrecios(precio_lista = 1) {
      this.precios.precio_1 = (precio_lista * this.comision_1).toFixed(2);
      this.precios.precio_2 = (precio_lista * this.comision_2).toFixed(2);
      this.precios.precio_3 = (precio_lista * this.comision_3).toFixed(2);
      this.precios.precio_4 = (precio_lista * this.comision_4).toFixed(2);
      this.precios.precio_5 = (precio_lista * this.comision_5).toFixed(2);
    },
    obtenerComisiones(proveedor_id) {
      this.cargando = true;
      api("GET", `proveedores/consultar-proveedor/${proveedor_id}`).then(
        async (result) => {
          if (result.errorCode) {
            Notification(
              "error",
              "Obtener Comisiones",
              "Error al obtener las comisiones del proveedor."
            );
            this.resetComisiones();
            this.cargando = false;
          } else {
            this.comision_1 = result.comision_1;
            this.comision_2 = result.comision_2;
            this.comision_3 = result.comision_3;
            this.comision_4 = result.comision_4;
            this.comision_5 = result.comision_5;
            if (this.precio_lista !== 0) {
              this.calcularPrecios(this.precio_lista);
            }
            this.cargando = false;
          }
        }
      );
    },
    resetComisiones() {
      this.comision_1 = 1;
      this.comision_2 = 1;
      this.comision_3 = 1;
      this.comision_4 = 1;
      this.comision_5 = 1;
    },
  },
};
</script>
<style scoped></style>