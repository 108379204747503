<template>
  <b-modal id="modal-importar" ref="modal-importar" size="xl" title="Importar Productos" scrollable hide-footer>
    <b-overlay id="overlay-background" :show="loading" variant="light" opacity="0.7" blur="2px" rounded="sm">
      <b-row>
        <b-col>
          <h3>Proveedor</h3>
          <b-form-select v-model="proveedorSelected" :options="proveedoresItems"></b-form-select>
        </b-col>
        <b-col>
          <h3>Excel</h3>
          <b-form-file v-model="archivo" accept=".xlsx" placeholder="Elija o suelte un archivo Excel."></b-form-file>
        </b-col>
      </b-row>

      <h3 class="mt-3">Marca</h3>
      <b-form-select v-model="marcaSelected" :options="marcasItems" />

      <b-row>
        <b-col>
          <h3>Comision 1</h3>
          <b-form-input v-model="comisiones.comision_1" type="number"/>
        </b-col>

        <b-col>
          <h3>Comision 2</h3>
          <b-form-input v-model="comisiones.comision_2" type="number"/>
        </b-col>

        <b-col>
          <h3>Comision 3</h3>
          <b-form-input v-model="comisiones.comision_3" type="number"/>
        </b-col>

        <b-col>
          <h3>Comision 4</h3>
          <b-form-input v-model="comisiones.comision_4" type="number"/>
        </b-col>

        <b-col>
          <h3>Comision 5</h3>
          <b-form-input v-model="comisiones.comision_5" type="number"/>
        </b-col>
      </b-row>

      <b-table-simple small hover sticky-header class="mt-3" v-if="tabla.length > 0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">Codigo</b-th>
            <b-th>Descripcion</b-th>
            <b-th class="text-center">Precio 1</b-th>
            <b-th class="text-center">Precio 2</b-th>
            <b-th class="text-center">Precio 3</b-th>
            <b-th class="text-center">Precio 4</b-th>
            <b-th class="text-center">Precio 5</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item of tabla" :key="item.codigo">
            <b-td class="text-center">{{ item.Codigo }}</b-td>
            <b-td>{{ item.Descripcion }}</b-td>
            <b-td class="text-right">{{ item['Precio 1'] }}</b-td>
            <b-td class="text-right">{{ item['Precio 2'] }}</b-td>
            <b-td class="text-right">{{ item['Precio 3'] }}</b-td>
            <b-td class="text-right">{{ item['Precio 4'] }}</b-td>
            <b-td class="text-right">{{ item['Precio 5'] }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="text-right">
        <b-button v-if="tabla.length > 0 && proveedorSelected && marcaSelected" variant="primary"
          @click="guardar">Guardar</b-button>
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
import readExcelFile from 'read-excel-file';
import { axiosInstance } from '../../../../../utils/axiosInstance';


export default {
  data() {
    return {
      comisiones: {
        comision_1: 0,
        comision_2: 0,
        comision_3: 0,
        comision_4: 0,
        comision_5: 0,
      },
      proveedores: [],
      proveedorSelected: null,
      proveedorDetalles: null,
      marcas: [],
      marcaSelected: null,
      archivo: null,
      tabla: [],
      loading: false,
    };
  },
  watch: {
    archivo(nuevo) {
      if (!nuevo) {
        return;
      }
      readExcelFile(this.archivo)
        .then(rows => {
          let headers = [];
          this.tabla = [];
          rows.forEach((row, index) => {
            if (index === 0) {
              headers = row;
            } else {
              this.tabla.push({
                [headers[0]]: row[0],
                [headers[1]]: row[1],
                [headers[2]]: row[2],
                [headers[3]]: row[3],
                [headers[4]]: row[4],
                [headers[5]]: row[5],
                [headers[6]]: row[6],
              });
            }
          })
        })
        .catch((err) => this.makeToast('Error', 'Hubo un error al leer el Excel. ' + err, 'danger', 2500))
    },
    async proveedorSelected(nuevo) {
      if (!nuevo) {
        this.proveedorDetalles = null;
      } else {
        try {
          const result = await axiosInstance.get(`proveedores/consultar-proveedor/${nuevo}`);
          console.log(result);
        } catch (error) {
          this.makeToast('Error', 'Hubo un error al consultar el proveedor seleccionado', 'danger', 2000);
        }
      }
    }
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    async guardar() {
      this.loading = true;

      try {
        const productosExistentes = await axiosInstance.post('productos/importar', {
          productos: this.tabla,
          marca: this.marcaSelected,
          proveedor: this.proveedorSelected,
          comisiones: this.comisiones
        }, {
          
          responseType: 'blob'
        })

        if (productosExistentes.data) {
          const url = window.URL.createObjectURL(new Blob([productosExistentes.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `ProductosExistentes.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        this.makeToast('Éxito', 'Productos importados.', 'success', 2500);
      } catch (error) {
        this.makeToast('Error', 'Hubo un error al importar productos.', 'danger', 2500);
      } finally {
        this.loading = false;
      }
    }
  },
  async beforeMount() {
    this.loading = true;
    try {
      const result = await axiosInstance.get('proveedores/obtener-proveedores', {
        headers: {
          authorization: localStorage.getItem('token')
        }
      });

      const marcas = await axiosInstance.get('marcas', {
        headers: {
          authorization: localStorage.getItem('token')
        }
      })

      this.marcas = marcas.data;
      this.proveedores = result.data.proveedores;
    } catch (error) {
      this.makeToast('Error', 'Hubo un error al obtener los proveedores.', 'danger', 2000);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    proveedoresItems() {
      const array = [{ value: null, text: 'Seleccione proveedor' }];

      this.proveedores.forEach(proveedor => {
        array.push({ value: proveedor.proveedor_id, text: proveedor.nombre })
      })

      return array;
    },
    marcasItems() {
      const array = [{ value: null, text: 'Seleccione una marca' }];

      this.marcas.forEach(marca => {
        array.push({ value: marca.marca_id, text: marca.nombre })
      })

      return array;
    }
  }
};
</script>
