<template>
  <div id="ListaProductosAbc">
    <AgregarProducto ref="modal-agregar" :listaProveedoresSelect="listaProveedoresSelect"
      :listaMarcasSelect="listaMarcasSelect" @recargar-productos="recargarProductos" />

    <ImportarProductos />

    <div class="wrap">
      <section class="barra-buscar">
        <div style="width: 100%; margin: 0px 10px">
          <multiselect style="width: 100%" v-model="selected" deselect-label="Can't remove this value" track-by="name"
            label="name" placeholder="Select one" :multiple="true" :options="listaProveedoresSelectMulti"
            :searchable="true" :allow-empty="true">
            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
          </multiselect>
        </div>

        <b-form-input id="input-query-codigo" v-model="queryCodigo" type="search" placeholder="Codigo"
          @keyup.enter="buscarProductos" />

        <b-form-input id="input-query" v-model="query" type="search" placeholder="Buscar..."
          @keyup.enter="buscarProductos" />

        <b-button id="btn-buscar" variant="outline-primary" @click="buscarProductos">BUSCAR</b-button>

        <b-button class="btn-agregar" v-b-modal.modal-agregar
          @click="$refs['modal-agregar'].limpiar()">AGREGAR</b-button>
      </section>

      <section class="d-flex justify-content-end">
        <b-button variant="outline-primary" v-b-modal.modal-importar>Importar productos</b-button>
      </section>

      <section class="tabla-productos">
        <Tabla :items="itemsTabla" :fields="fields" :isBusy="isBusy" :rows="rows" :size="true"
          @row-selected="onRowSelected" />

        <div class="pagination_container">
          <b-pagination v-model="page" :total-rows="rows" :per-page="numPerPage" first-text="Inicio"
            prev-text="Anterior" next-text="Siguiente" last-text="Ultimo" :disabled="isBusy"></b-pagination>

          <span id="title_select_perp_age" class="mx-3">Resultados</span>

          <b-form-select v-model="numPerPage" :options="optionsPerPage"></b-form-select>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Tabla from "../../../../../components/base/_BaseTablaProductos.vue";
import AgregarProducto from "./AgregarProducto.vue";
import ImportarProductos from "./ImportarProductos.vue";
import { axiosInstance } from "../../../../../utils/axiosInstance";
import Notification from "../../../../../utils/notification";

export default {
  name: "ListaProductosAbc",
  props: ["listar"],
  data() {
    return {
      items: [],
      selected: [],
      fields: [
        {
          key: "codigo",
          sortable: true,
          label: "Código",
        },
        {
          key: "subcodigo",
          sortable: true,
          label: "Subcódigo",
        },
        {
          key: "descripcion",
          sortable: true,
          label: "Descripción",
        },
        {
          key: "imagen",
          sortable: false,
          label: "Fotos",
        },
        {
          key: "proveedor",
          sortable: true,
          label: "Proveedor",
        },
        {
          key: "precio",
          sortable: true,
          label: "Precio",
        },
        {
          key: "precioIva",
          sortable: true,
          label: "Precio con IVA",
        },
        {
          key: "empaque",
          sortable: true,
          label: "Empaque",
        },
        {
          key: "venta_min",
          sortable: true,
          label: "Venta Min.",
        },
      ],
      optionsPerPage: [
        { value: 0, text: "Todos" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 500, text: "500" },
        { value: 1000, text: "1000" },
        { value: 5000, text: "5000" },
      ],
      isBusy: true,
      productoSeleccionado: null,
      page: 1,
      numPerPage: 20,
      query: null,
      rows: 0,
      proveedorSelected: null,
      mostrarDetalles: false,
      currentProducto: null,
      reloadProductList: false,
      queryCodigo: "",
    };
  },
  components: {
    Tabla,
    Multiselect,
    AgregarProducto,
    ImportarProductos
  },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
      obtenerMarcas: "ObtenerMarcas",
    }),
    async buscarProductos() {
      this.isBusy = true;
      try {
        const body = {
          codigo: this.queryCodigo,
          suspendido: 0,
          proveedores: this.selected.map((item) => item.value),
          descripcion: this.query,
          page: this.page,
          numPerPage: this.numPerPage,
        }

        const result = await axiosInstance.get('productos', { params: body })
        this.items = result.data.productos
        this.rows = result.data.count
      } catch (error) {
        Notification('error', 'Consultar productos', error.response.data.msg || 'Hubo un error en el servidor', 3000)
      } finally {
        this.isBusy = false;
      }
    },
    onRowSelected(item) {
      this.$emit("row-selected", item);
    },
    recargarProductos() {
      this.$bvModal.hide("modal-agregar");
      this.buscarProductos();
    },
    abrirImportar() {
      this.$bvModal.show('modal-importar');
    },
  },
  computed: {
    ...mapGetters([
      "listaProveedoresSelect",
      "listaProveedoresSelectMulti",
      "listaMarcasSelect",
    ]),
    itemsTabla() {
      let array = []

      for (const producto of this.items) {
        array.push({
          producto_id: producto.producto_id,
          codigo: producto.codigo,
          subcodigo: producto.subcodigo,
          descripcion: producto.descripcion,
          tieneFoto: producto.tieneFoto,
          proveedor: producto.proveedor.nombre_corto,
          precio: producto.precio.precio_lista,
          empaque: producto.empaque,
          venta_min: producto.venta_min,
          iva: producto.iva,
        })
      }

      return array
    }
  },
  mounted() {
    this.obtenerProveedores();
    this.obtenerMarcas();
    this.buscarProductos();
  },
  watch: {
    async page() {
      await this.buscarProductos();
    },
    async reloadProductList() {
      await this.listarProductos();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>