<template>
  <b-modal id="modal-agregar" ref="modal-agregar" size="xl" title="Agregar Producto" scrollable hide-footer>
    <div>
      <b-row>
        <b-col>
          <div class="input">
            <label for="codigo">Codigo: </label>
            <b-form-input v-model="productoNuevo.codigo" id="codigo" type="text"
              v-on:blur="comprobarCodigo"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label for="subcodigo">Sub Codigo: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.subcodigo" id="subcodigo"
              type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="input">
            <label for="codigo_fab">Codigo fabrica: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.codigo_fab" id="codigo_fab"
              type="text"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label for="venta_min">Venta minima: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.venta_min" id="venta_min"
              type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="input">
            <label for="descripcion">Descripcion: </label>
            <b-form-input v-model="productoNuevo.descripcion" id="descripcion" type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="input">
            <label for="empaque">Empaque: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.empaque" id="empaque"
              type="text"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label for="subempaque">Subempaque: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.subempaque" id="subempaque"
              type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="input">
            <label for="sobrepedido">Sobrepedido: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.sobrepedido" id="sobrepedido"
              type="text"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label for="min_sobrepedido">Minimo sobrepedido: </label>
            <b-form-input :disabled="bloquearForms" v-model="productoNuevo.min_sobrepedido" id="min_sobrepedido"
              type="text"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="input">
            <label for="codigo">Marca: </label>
            <b-form-select :disabled="bloquearForms" v-model="productoNuevo.marca_id"
              :options="listaMarcasSelect"></b-form-select>
          </div>
        </b-col>
        <b-col>
          <div class="input">
            <label for="codigo">Proveedor: </label>
            <b-form-select :disabled="bloquearForms" v-model="proveedor_id"
              :options="listaProveedoresSelect"></b-form-select>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <label for="factor_1">Comision 1:</label>
            <b-form-input :disabled="bloquearForms" id="factor_1" v-model="productoNuevo.factor_1"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_2">Comision 2:</label>
            <b-form-input :disabled="bloquearForms" id="factor_2" v-model="productoNuevo.factor_2"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_3">Comision 3:</label>
            <b-form-input :disabled="bloquearForms" id="factor_3" v-model="productoNuevo.factor_3"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_4">Comision 4:</label>
            <b-form-input :disabled="bloquearForms" id="factor_4" v-model="productoNuevo.factor_4"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="factor_5">Comision 5:</label>
            <b-form-input :disabled="bloquearForms" id="factor_5" v-model="productoNuevo.factor_5"
              type="number"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <label for="iva">IVA:</label>
            <b-form-input :disabled="bloquearForms" id="iva" v-model="productoNuevo.iva" type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_lista">Precio Lista:</label>
            <b-form-input :disabled="bloquearForms" id="precio_lista" v-model="precio_lista"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="ieps">I.E.P.S.:</label>
            <b-form-input :disabled="bloquearForms" id="ieps" v-model="productoNuevo.ieps" type="number"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <label for="precio_1">Precio 1:</label>
            <b-form-input :disabled="bloquearForms" readonly id="precio_1" v-model="productoNuevo.precio_1"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_2">Precio 2:</label>
            <b-form-input :disabled="bloquearForms" readonly id="precio_2" v-model="productoNuevo.precio_2"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_3">Precio 3:</label>
            <b-form-input :disabled="bloquearForms" readonly id="precio_3" v-model="productoNuevo.precio_3"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_4">Precio 4:</label>
            <b-form-input :disabled="bloquearForms" readonly id="precio_4" v-model="productoNuevo.precio_4"
              type="number"></b-form-input>
          </div>
        </b-col>
        <b-col>
          <div>
            <label for="precio_5">Precio 5:</label>
            <b-form-input :disabled="bloquearForms" readonly id="precio_5" v-model="productoNuevo.precio_5"
              type="number"></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row class="buttonsModalRow">
        <b-col>
          <b-button @click="guardarProducto">GUARDAR</b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import api from "../../../../../utils/api";
import Notification from "../../../../../utils/notification";

export default {
  name: "AgregarProducto",
  props: ["listaMarcasSelect", "listaProveedoresSelect"],
  data() {
    return {
      productoNuevo: {
        codigo: "",
        subcodigo: "",
        codigo_fab: "",
        descripcion: "",
        empaque: "",
        subempaque: "",
        sobrepedido: 0,
        min_sobrepedido: "",
        venta_min: "",
        iva: 0.16,
        marca_id: null,
        proveedor_id: null,
        precio_lista: 0,
        ieps: 0,
        precio_1: 0,
        precio_2: 0,
        precio_3: 0,
        precio_4: 0,
        precio_5: 0,
        factor_1: 0,
        factor_2: 0,
        factor_3: 0,
        factor_4: 0,
        factor_5: 0,
        usuario_id: sessionStorage.usuario_id,
      },
      marca_options: [],
      proveedor_options: [],
      cargando: true,
      proveedor_id: null,
      precio_lista: 0,
      comision_1: 1,
      comision_2: 1,
      comision_3: 1,
      comision_4: 1,
      comision_5: 1,
      bloquearForms: false,
    };
  },
  methods: {
    guardarProducto() {
      if (!this.precio_lista || this.precio_lista <= 0) {
        Notification(
          "error",
          "Guardar Producto",
          "El precio lista debe ser mayor a 0"
        );
        return;
      }

      if(!this.productoNuevo.codigo) {
        Notification('error', 'Guardar Producto', 'El código de producto es obligatorio', 3000)
        return
      }

      if(!this.productoNuevo.proveedor_id) {
        Notification('error', 'Guardar Producto', 'Debe seleccionar un proveedor', 3000)
        return
      }

      if(!this.productoNuevo.marca_id) {
        Notification('error', 'Guardar Producto', 'Debe seleccionar una marca', 3000)
        return
      }

      if(!this.productoNuevo.descripcion) {
        Notification('error', 'Guardar Producto', 'La descripción es obligatoria', 3000)
        return
      }

      this.cargando = true;
      this.productoNuevo.proveedor_id = this.proveedor_id;
      this.productoNuevo.precio_lista = this.precio_lista;
      this.productoNuevo.subcodigo = this.productoNuevo.subcodigo.toString();
      delete this.productoNuevo.producto_id;
      delete this.productoNuevo.precio_id;
      delete this.productoNuevo.precio_inicial_id;
      delete this.productoNuevo.precio_anterior_id;
      delete this.productoNuevo.suspendido;
      delete this.productoNuevo.id_original;
      delete this.productoNuevo.fecha_alta;
      delete this.productoNuevo.ultima_modificacion;
      delete this.productoNuevo.usuario_modificacion;
      api("POST", "productos-paginados/agregar-producto", this.productoNuevo)
        .then(async (result) => {
          if (result.errorCode) {
            Notification(
              "error",
              "Guardar Producto",
              "Error al guardar el producto"
            );
            this.$emit("recargar-productos");
          } else {
            Notification("success", "Guardar Producto", "Producto Guardado!!!");
            this.$emit("recargar-productos");
          }
        })
        .catch((error) => {
          Notification("error", "Error", error.response.data.msg || "Error del servidor");
        })
        .finally(() => this.cargando = false)
    },
    obtenerComisiones(proveedor_id) {
      this.cargando = true;
      api("GET", `proveedores/consultar-proveedor/${proveedor_id}`).then(async (result) => {
        if (result.errorCode) {
          Notification(
            "error",
            "Obtener Comisiones",
            "Error al obtener las comisiones del proveedor."
          );
          this.resetComisiones();
          this.cargando = false;
        } else {
          this.comision_1 = result.comision_1;
          this.comision_2 = result.comision_2;
          this.comision_3 = result.comision_3;
          this.comision_4 = result.comision_4;
          this.comision_5 = result.comision_5;
          if (this.precio_lista !== 0) {
            await this.calcularPrecios(this.precio_lista);
          }
          this.cargando = false;
        }
      });
    },
    resetComisiones() {
      this.comision_1 = 1;
      this.comision_2 = 1;
      this.comision_3 = 1;
      this.comision_4 = 1;
      this.comision_5 = 1;
    },
    calcularPrecios(precio_lista) {
      this.productoNuevo.precio_1 = (precio_lista * this.comision_1).toFixed(2);
      this.productoNuevo.precio_2 = (precio_lista * this.comision_2).toFixed(2);
      this.productoNuevo.precio_3 = (precio_lista * this.comision_3).toFixed(2);
      this.productoNuevo.precio_4 = (precio_lista * this.comision_4).toFixed(2);
      this.productoNuevo.precio_5 = (precio_lista * this.comision_5).toFixed(2);
    },
    comprobarCodigo() {
      if (this.productoNuevo.codigo.length && this.proveedor_id) {
        this.bloquearForms = true;
        this.productoNuevo.proveedor_id = this.proveedor_id;
        api(
          "POST",
          "productos-paginados/consultar-codigos-repetidos",
          this.productoNuevo
        ).then(async (result) => {
          if (result.errorCode) {
            Notification(
              "error",
              "Consultar productos repetidos",
              result.errorMessage
            );
            this.bloquearForms = false;
          } else {
            if (result.message) {
              this.bloquearForms = false;
            }

            else {
              this.productoNuevo = { ...result };

              if (result.precio_lista) {
                this.precio_lista = result.precio_lista;
              }

              try {
                this.productoNuevo.subcodigo = parseInt(result.subcodigo) + 1;
              } catch (error) {
                this.productoNuevo.subcodigo = result.subcodigo;
              }
            }
          }
        });
      }
    },
    limpiar() {
      this.productoNuevo = {
        codigo: "",
        subcodigo: "",
        codigo_fab: "",
        descripcion: "",
        empaque: "",
        subempaque: "",
        sobrepedido: 0,
        min_sobrepedido: "",
        venta_min: "",
        iva: 0.16,
        marca_id: null,
        proveedor_id: null,
        precio_lista: 0,
        ieps: 0,
        precio_1: 0,
        precio_2: 0,
        precio_3: 0,
        precio_4: 0,
        precio_5: 0,
        factor_1: 0,
        factor_2: 0,
        factor_3: 0,
        factor_4: 0,
        factor_5: 0,
        usuario_id: sessionStorage.usuario_id,
      }

      this.marca_options = []
      this.proveedor_options = []
      this.cargando = true
      this.proveedor_id = null
      this.precio_lista = 0
      this.comision_1 = 1
      this.comision_2 = 1
      this.comision_3 = 1
      this.comision_4 = 1
      this.comision_5 = 1
      this.bloquearForms = false
    },
  },
  watch: {
    proveedor_id: function (val) {
      this.obtenerComisiones(val);
      this.comprobarCodigo();
    },
    precio_lista: function (val) {
      this.calcularPrecios(val);
    },
  },
};
</script>
<style>
.input {
  margin: 2px 0;
}

div>.row {
  margin-top: 10px;
}

.buttonsModalRow button {
  width: 100%;
  margin: 10px auto;
  background-color: #355070;
  border-color: #355070;
}
</style>
